import React, { useCallback, useEffect, useRef } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { coordResultsProps } from "../../typescript/interfaces/MapLocationInt";

interface ValidateMapProps {
  mapResults: coordResultsProps;
}

const libraries: ("places" | "geometry" | "drawing")[] = ["places"];

function ValidateMap({ mapResults }: ValidateMapProps) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyB3OJgL9-g2f6rIxua7rxV0ST2hcgsqppw", // Use your API key here
    libraries,
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const circleRef = useRef<google.maps.Circle | null>(null);

  const updateCircle = useCallback(() => {
    if (mapRef.current) {
      const map = mapRef.current;
      map.panTo(mapResults.center);

      if (circleRef.current) {
        circleRef.current.setMap(null);
      }

      circleRef.current = new google.maps.Circle({
        map,
        center: mapResults.center,
        radius: mapResults.radius,
        fillColor: "#15BC7F",
        fillOpacity: 0.2,
        strokeColor: "#15BC7F",
        strokeOpacity: 0.5,
        strokeWeight: 2,
      });

      const bounds = circleRef.current.getBounds();
      if (bounds) {
        map.fitBounds(bounds);
      }
    }
  }, [mapResults]); // Add mapResults to the dependency array

  useEffect(() => {
    if (mapRef.current) {
      updateCircle();
    }
  }, [mapResults, updateCircle]);

  const onMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    map.setOptions({
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
    });
    updateCircle(); // Add this line
  };

  if (!isLoaded) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="validateMap">
      <GoogleMap
        mapContainerStyle={{
          height: "300px",
          width: "100%",
          borderRadius: "10px",
          cursor: "default",
          border: "1px solid rgba(0,0,0,0.2)",
        }}
        center={mapResults.center}
        zoom={10}
        onLoad={onMapLoad}
        options={{
          disableDefaultUI: true,
          gestureHandling: "none",
        }}
      />
    </div>
  );
}

export default ValidateMap;
