import React from "react";

interface AIScoreProps {
  score: number;
}

function AIScore(props: AIScoreProps) {
  const radius = 40; // Increase radius to make the circle bigger
  const circumference = 2 * Math.PI * radius;
  const inputNumber = props.score <= 2 ? 2 : props.score;
  const offset = circumference - (inputNumber / 10) * circumference;

  return (
    <span style={{ display: "inline-flex", alignItems: "left" }}>
      <svg width="40px" height="40px" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r={radius}
          stroke="#e6e6e6"
          fill="none"
          strokeWidth="12" // Adjust stroke width proportionally
        />
        <circle
          cx="50"
          cy="50"
          r={radius}
          stroke="#15BC7F"
          fill="none"
          strokeWidth="12" // Adjust stroke width proportionally
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform="rotate(-90 50 50)"
        />
        <text
          x="50"
          y="50"
          textAnchor="middle"
          fontSize="45"
          fill="#333"
          dy=".3em"
        >
          {inputNumber}
        </text>
      </svg>
    </span>
  );
}

export default AIScore;
