import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../firebase";
import Navbar from "../../components/Navbar";

function RecoverPassword() {
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");

  const handleForgotPassword = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoadingAuth(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setResetEmailSent(true);
      setErrorMessage("");
    } catch (err: any) {
      switch (err.code) {
        case "auth/invalid-email":
          setErrorMessage("Por favor, introduce un correo electrónico válido.");
          break;
        case "auth/user-not-found":
          setErrorMessage("No existe una cuenta con este correo electrónico.");
          break;
        default:
          setErrorMessage("Error al enviar el correo de recuperación.");
      }
    } finally {
      setLoadingAuth(false);
    }
  };
  return (
    <React.Fragment>
      <Navbar scrollPast={true} lockNavbar={true} />
      <div className="recover-password-container">
        <div className="recover-password">
          <h2 style={{ marginBottom: "20px" }}>Recuperar Contraseña</h2>
          <p
            className="recover-description"
            style={{ marginBottom: "20px", color: "#666" }}
          >
            Introduce tu correo electrónico y te enviaremos un enlace para
            restablecer tu contraseña. El enlace será válido durante 24 horas.
          </p>
          <form
            onSubmit={(e) =>
              handleForgotPassword(e as unknown as React.MouseEvent)
            }
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div className="input-group">
              <label
                htmlFor="email"
                style={{ marginBottom: "5px", display: "block" }}
              >
                Correo Electrónico
              </label>
              <input
                id="email"
                type="email"
                style={{ padding: "10px", width: "100%", fontSize: "16px" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="ejemplo@correo.com"
                required
              />
            </div>
            {errorMessage && (
              <p className="error" style={{ color: "#dc3545" }}>
                {errorMessage}
              </p>
            )}
            {resetEmailSent && (
              <p className="success" style={{ color: "#28a745" }}>
                Se ha enviado un correo de recuperación a tu dirección de correo
                electrónico. Por favor, revisa también tu carpeta de spam.
              </p>
            )}
            <button
              type="submit"
              className="recover-password-button"
              disabled={loadingAuth}
            >
              {loadingAuth ? "Enviando..." : "Enviar correo de recuperación"}
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RecoverPassword;
