import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StateColors from "../../applicants-sidebar/StateColors";

interface FilterDropdownProps {
  options: string[];
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
}
function FilterDropdown({ options, state, setState }: FilterDropdownProps) {
  const [open, setOpen] = useState(false);
  return (
    <div className="filter-dropdown-container ">
      <div className=" filter-dropdown">
        <div
          className={`asdd-box-header filter-dropdown-header  ${
            open ? "asdd-box-header-open" : ""
          }`}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="flx" style={{ alignItems: "center" }}>
            <span style={{ color: "rgba(0,0,0,0.6)", marginRight: "5px" }}>
              Estado:
            </span>
            <StateColors state={state} />
          </div>
          <ArrowDropDownIcon
            style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </div>
        <div className={`filter-dropdown-box ${!open && "hide"} `}>
          {options.map((option, index) => {
            return (
              <div
                className="asdd-box-item"
                key={index}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  setState(option);
                  setOpen(false);
                }}
              >
                <StateColors state={option} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FilterDropdown;
