import React from "react";

type StateColorsProps = {
  state: string;
};

const stateColorMap: { [key: string]: string } = {
  Guardados: "#FFBB32", // Orange
  Nuevos: "#15BC7F", // Green
  Rechazados: "#BC1515", // Red
  // Add more states and colors as needed
};

function StateColors({ state }: StateColorsProps) {
  const color = stateColorMap[state] || "rgba(0,0,0,0.4)"; // Default to gray if state not found

  return (
    <div className="w100">
      {state && (
        <div
          style={{
            backgroundColor: color,
            borderRadius: "50px",
            padding: "5px 12px",
            color: "white",
            fontSize: "14px",
            width: "fit-content",
          }}
        >
          {state}
        </div>
      )}
    </div>
  );
}

export default StateColors;
