import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ApplicantInt } from "../../../typescript/interfaces/AppInterface";
import StateColors from "../../applicants-sidebar/StateColors";

interface ApplicantStateDropDownProps {
  applicant: ApplicantInt;
  setApplicantsState: (
    event: React.MouseEvent<HTMLDivElement>,
    selectedApplicant: ApplicantInt,
    state: "Nuevos" | "Guardados" | "Rechazados"
  ) => Promise<void>;
}
function ApplicantStateDropDown({
  applicant,
  setApplicantsState,
}: ApplicantStateDropDownProps) {
  const [open, setOpen] = useState(false);
  const options = ["Nuevos", "Guardados", "Rechazados"];
  return (
    <div className="asdd-container">
      <div
        className={`asdd-box-header  ${open ? "asdd-box-header-open" : ""}`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div>
          <StateColors state={applicant.state} />
        </div>
        <ArrowDropDownIcon
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        />
      </div>

      <div className={`asdd-box ${!open && "hide"} `}>
        {options.map((option, index) => {
          if (option !== applicant.state) {
            return (
              <div
                className="asdd-box-item"
                key={index}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  setApplicantsState(
                    e,
                    applicant,
                    option as "Nuevos" | "Guardados" | "Rechazados"
                  );
                  setOpen(false);
                }}
              >
                <StateColors state={option} />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default ApplicantStateDropDown;
