import { useState, useCallback, useEffect } from "react";
import "../../assets/styles/dashboard/positions.css";
import { JobInt } from "../../typescript/interfaces/JobInterface";
import JobEditPopUp from "./JobEditPopUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import IosShareIcon from "@mui/icons-material/IosShare";
import ConfirmPopUp from "./tools/ConfirmPopUp";
import { deleteJobById } from "../../utils/jobsUtils";
interface JobPostingRowProps {
  job: JobInt;
  getPositions: () => Promise<void>;
}

function JobPostingRow(props: JobPostingRowProps) {
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [showJobPopUp, setShowJobPopUp] = useState(false);
  const [copyMessage, setCopyMessage] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const toggleBodyScroll = (disable: boolean) => {
      if (disable) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    };

    toggleBodyScroll(showJobPopUp || showConfirmPopUp);

    return () => {
      toggleBodyScroll(false);
    };
  }, [showJobPopUp, showConfirmPopUp]);

  const handleClick = () => {
    setShowJobPopUp(true);
  };

  const parseDate = (date: Date) => {
    const dateObject = new Date(date);
    const today = new Date();
    const timeDifference = today.getTime() - dateObject.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    const formattedDate = dateObject.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return (
      <>
        <div>{formattedDate}</div>({daysDifference} días)
      </>
    );
  };

  const handleCopy = useCallback(() => {
    const jobUrl = `${window.location.origin}/job-des/job-apply?id=${props.job._id}`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(jobUrl)
        .then(() => {
          setCopyMessage("Enlace Copiado");
          setTimeout(() => setCopyMessage(null), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          setCopyMessage("Error al copiar");
          setTimeout(() => setCopyMessage(null), 2000);
        });
    } else {
      // Fallback for browsers that don't support clipboard API
      const textArea = document.createElement("textarea");
      textArea.value = jobUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        setCopyMessage("Copiado");
        setTimeout(() => setCopyMessage(null), 2000);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
        setCopyMessage("Error al copiar");
        setTimeout(() => setCopyMessage(null), 2000);
      }
      document.body.removeChild(textArea);
    }
  }, [props.job._id]);

  const handleDelete = async () => {
    setIsDeleting(true);
    if (await deleteJobById(props.job._id as string)) {
      console.log("deleted job", props.job._id);
      // Optionally, you can call props.getPositions() here to refresh the list
    } else {
      // If deletion fails, set isDeleting back to false
      setIsDeleting(false);
    }
    setShowConfirmPopUp(false);
  };

  if (isDeleting) {
    return null; // Don't render anything if the job is being deleted
  }

  return (
    <div className="positions-box-table-row">
      <div className="positions-box-table-cell pbtc-fr">{props.job.title}</div>
      <div className="positions-box-table-cell desktop-only">
        {props.job.location.city && props.job.location.country
          ? `${props.job.location.city}, ${props.job.location.country}`
          : ""}
      </div>
      <div className="positions-box-table-cell" style={{ marginLeft: "10px" }}>
        {props.job.applicants || 0}
      </div>
      <div className="positions-box-table-cell desktop-only">
        {parseDate(props.job.datePosted)}
      </div>
      <div
        className="positions-box-table-cell desktop-only"
        style={{
          color: props.job.status ? "var(--laburo-green)" : "gray",
        }}
      >
        {props.job.status ? "activo" : "pausado"}
      </div>
      <div
        className="positions-box-table-cell mobile-only"
        style={{
          color: props.job.status ? "var(--laburo-green)" : "gray",
        }}
      >
        {props.job.status ? "Sí" : "No"}
      </div>

      <div
        className="positions-box-table-cell btc-edit flx flx-center"
        style={{ gap: "15px" }}
      >
        <div className="share-icon-container" style={{ position: "relative" }}>
          <div onClick={handleCopy}>
            <IosShareIcon style={{ fontSize: "20px" }} />
          </div>
          {copyMessage && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "white",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "12px",
                whiteSpace: "nowrap",
                zIndex: 1000,
              }}
            >
              {copyMessage}
            </div>
          )}
        </div>
        <div onClick={handleClick}>
          <EditIcon style={{ fontSize: "20px" }} />
        </div>
        <div onClick={() => setShowConfirmPopUp(true)}>
          <DeleteOutlineIcon style={{ fontSize: "20px" }} />
        </div>
      </div>

      {showJobPopUp && (
        <JobEditPopUp
          setJobPopUp={setShowJobPopUp}
          job={props.job}
          getPositions={props.getPositions}
        />
      )}
      {showConfirmPopUp && (
        <ConfirmPopUp
          question="¿Estás seguro/a de que deseas eliminar esta vacante?"
          onConfirm={handleDelete}
          onCancel={() => {
            setShowConfirmPopUp(false);
          }}
        />
      )}
    </div>
  );
}

export default JobPostingRow;
