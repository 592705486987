import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterDropdown from "./tools/FilterDropdown";

interface AppllicantFilterPopUpProps {
  name: string;
  setPopUpOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleLocationFilterChange: (
    filter: "Todos los aplicantes" | "Dentro de la zona" | "Afuera de la zona"
  ) => void;
  showLocationFilter: boolean;
  locationFilterChange:
    | "Todos los aplicantes"
    | "Dentro de la zona"
    | "Afuera de la zona";
  stateFilterChange: "Todos" | "Nuevos" | "Guardados" | "Rechazados";
  handleStateFilterChange: (
    filter: "Todos" | "Nuevos" | "Guardados" | "Rechazados"
  ) => void;
}

function ApplicantFilterPopUp(props: AppllicantFilterPopUpProps) {
  const [locationDropDownOpen, setLocationDropDownOpen] = useState(false);
  const [selectedLocationFilter, setSelectedLocationFitler] = useState<
    "Todos los aplicantes" | "Dentro de la zona" | "Afuera de la zona"
  >(props.locationFilterChange);

  const [applicantsStateFilter, setApplicantsStateFilter] = useState<
    "Todos" | "Nuevos" | "Guardados" | "Rechazados"
  >(props.stateFilterChange);

  const handleSubmit = (
    selection:
      | "Todos los aplicantes"
      | "Dentro de la zona"
      | "Afuera de la zona"
  ) => {
    props.handleLocationFilterChange(selection);
    props.handleStateFilterChange(applicantsStateFilter);
    props.setPopUpOpen(false);
  };
  return (
    <div className="dropdown-popup-container hide-for-desktop ">
      <div
        className="dropdown-popup-content"
        style={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      >
        <div className="w100">
          <div
            className="flx flx-center"
            style={{ fontSize: "22px", position: "relative" }}
          >
            {props.name}{" "}
            <div
              className="flx flx-center"
              style={{ position: "absolute", right: 5, top: 0 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.setPopUpOpen(false);
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="dropdown-popup-divider"></div>
          <div className="dropdown-popup-items" style={{ padding: "0px" }}>
            {props.showLocationFilter && (
              <div>
                <div style={{ marginBottom: "15px" }}>Filtro De Ubicación</div>
                <div className="ae-popup-dropdown-container">
                  <div
                    className={`ae-popup-dropdown ${
                      locationDropDownOpen ? "ae-popup-dropdown-open" : ""
                    }`}
                    onClick={() => {
                      setLocationDropDownOpen(!locationDropDownOpen);
                    }}
                  >
                    <div
                      className="flx"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            color: "rgba(0, 0, 0, 0.6)",
                            marginRight: "5px",
                          }}
                        >
                          Ubicación :
                        </span>
                        {selectedLocationFilter}
                      </div>
                      <span className="applicant-dropdown-arrow">
                        {locationDropDownOpen ? (
                          <ArrowDropDownIcon
                            style={{ transform: "rotate(180deg)" }}
                          />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </span>
                    </div>
                  </div>
                  {locationDropDownOpen && (
                    <div className="ae-popup-dropdown-options">
                      {" "}
                      <div
                        className={`dropdown-option ${
                          selectedLocationFilter === "Todos los aplicantes"
                            ? "location-dropdown-option-selected"
                            : ""
                        }`}
                        onClick={() => {
                          props.handleLocationFilterChange(
                            "Todos los aplicantes"
                          );
                          setSelectedLocationFitler("Todos los aplicantes");
                          setLocationDropDownOpen(false);
                        }}
                      >
                        Todos los aplicantes
                      </div>
                      <div
                        className={`dropdown-option ${
                          selectedLocationFilter === "Dentro de la zona"
                            ? "location-dropdown-option-selected"
                            : ""
                        }`}
                        onClick={() => {
                          props.handleLocationFilterChange("Dentro de la zona");
                          setSelectedLocationFitler("Dentro de la zona");
                          setLocationDropDownOpen(false);
                        }}
                      >
                        Dentro de la zona
                      </div>
                      <div
                        className={`dropdown-option ${
                          selectedLocationFilter === "Afuera de la zona"
                            ? "location-dropdown-option-selected"
                            : ""
                        }`}
                        onClick={() => {
                          props.handleLocationFilterChange("Afuera de la zona");
                          setSelectedLocationFitler("Afuera de la zona");
                          setLocationDropDownOpen(false);
                        }}
                      >
                        Afuera de la zona
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div>
              <div style={{ marginBottom: "15px" }}>Filtro De Estado</div>
              <FilterDropdown
                state={applicantsStateFilter}
                setState={
                  setApplicantsStateFilter as React.Dispatch<
                    React.SetStateAction<string>
                  >
                }
                options={
                  ["Todos", "Nuevos", "Guardados", "Rechazados"] as string[]
                }
              />
            </div>
          </div>
        </div>
        <div
          className="w100"
          onClick={() => {
            handleSubmit(selectedLocationFilter ?? "Todos los aplicantes");
          }}
        >
          <button className="dropdown-popup-button">Guardar</button>
        </div>
      </div>
    </div>
  );
}

export default ApplicantFilterPopUp;
