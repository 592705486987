import React from "react";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import "../../assets/styles/dashboard/applicants.css";
import ApplicantPill from "./ApplicantPill";
import ApplicantSideInfo from "./ApplicantSideInfo";

interface ApplicantSidebarProps {
  aplicants: ApplicantInt[];
  setApplicantLike: (
    event: React.MouseEvent,
    selectedApplicant: ApplicantInt
  ) => Promise<void>;
  setApplicantsState: (
    event: React.MouseEvent<HTMLDivElement>,
    selectedApplicant: ApplicantInt,
    state: "Nuevos" | "Guardados" | "Rechazados"
  ) => Promise<void>;
  selectedApplicant: ApplicantInt;
  setSelectedApplicant: (applicant: ApplicantInt) => void;
}

function ApplicantSidebar(props: ApplicantSidebarProps) {
  // useEffect(() => {
  //   if (!props.selectedApplicant && props.aplicants.length > 0) {
  //     props.setSelectedApplicant(props.aplicants[0]);
  //   }
  // }, [props.aplicants, props.selectedApplicant, props.setSelectedApplicant]);

  return (
    <div className="applicant-sidebar-container">
      <div className="applicant-sidebar-box ">
        <div className="applicant-sidebar-left-box">
          {props.aplicants.map((aplicant, index) => {
            return (
              <ApplicantPill
                key={aplicant._id}
                applicant={aplicant}
                setSelectedApp={props.setSelectedApplicant}
                selected={aplicant === props.selectedApplicant}
                setApplicantLike={props.setApplicantLike}
                setApplicantsState={props.setApplicantsState}
              />
            );
          })}
        </div>

        <div className="applicant-sidebar-right-box desktop-only">
          {props.selectedApplicant && (
            <ApplicantSideInfo
              selected={props.selectedApplicant}
              setApplicantsState={props.setApplicantsState}
            />
          )}
        </div>

        {/* popup  */}
      </div>
    </div>
  );
}

export default ApplicantSidebar;
