import React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";

interface TextEditorVisualizerProps {
  htmlContent: string;
}

function TextEditorVisualizer({ htmlContent }: TextEditorVisualizerProps) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Highlight,
      Underline,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    content: htmlContent,
    editable: false, // This makes it read-only
    editorProps: {
      attributes: {
        class: "prose prose-lg  custom-font",
        style: "font-family: Arial, sans-serif;",
      },
    },
  });

  React.useEffect(() => {
    if (editor && editor.getHTML() !== htmlContent) {
      editor.commands.setContent(htmlContent);
    }
  }, [editor, htmlContent]);

  if (!editor) {
    return null;
  }

  return <EditorContent editor={editor} />;
}

export default TextEditorVisualizer;
