import { ApplicantInt } from "../typescript/interfaces/AppInterface";
import { coordResultsProps } from "../typescript/interfaces/MapLocationInt";

const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371; // Earth's radius in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in kilometers
};

const toRadians = (degrees: number): number => {
  return degrees * (Math.PI / 180);
};

export function filterApplicantsByLocation(
  applicants: ApplicantInt[],
  jobLocation: coordResultsProps,
  withinRadius: boolean
): ApplicantInt[] {
  return applicants.filter((applicant) => {
    if (!applicant.mapResults) return false;
    const distance = calculateDistance(
      jobLocation.center.lat,
      jobLocation.center.lng,
      applicant.mapResults.center.lat,
      applicant.mapResults.center.lng
    );

    if (typeof distance !== "number") return false;
    const radiusInKm = jobLocation.radius / 1000; // Convert radius to km
    const isWithinRadius = distance <= radiusInKm;

    return withinRadius ? isWithinRadius : !isWithinRadius;
  });
}
