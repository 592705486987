import React, { useEffect, useState } from "react";
import { JobInt } from "../../typescript/interfaces/JobInterface";
import "../../assets/styles/job-sidebar.css";
import JobPost from "../JobPost";
import InfoFlag from "../job-card/InfoFlag";
import BoltIcon from "@mui/icons-material/Bolt";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import JobDescPopUp from "./JobDescPopUp";
import { Link } from "react-router-dom";
import TextEditorVisualizer from "../text-editor/TextEditorVisualizer";

interface JobSideDescProps {
  jobs: JobInt[];
}

function JobSideDesc(props: JobSideDescProps) {
  const [selectedJob, setSelectedJob] = useState<JobInt>();
  const [highlightJob, setHighlightJob] = useState("");
  const [PopupOpen, setPopupOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // You can adjust this threshold value based on your requirement
      if (window.scrollY > 200) {
        setIsPinned(true);
      } else {
        setIsPinned(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // set selected and highlighted job to first
    let firstjob = props.jobs[0];
    if (firstjob._id && window.innerWidth > 750) {
      setSelectedJob(firstjob);
      setHighlightJob(firstjob._id);
    }
  }, [props.jobs]);

  // make sure to stop the scrolling on popup and also retain the position you are on
  useEffect(() => {
    if (PopupOpen && window.innerWidth < 750) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [PopupOpen]);

  return (
    <div className="job-sidebar w100">
      <div
        style={{
          // maxWidth: "450px",

          width: "85%",
          // height: "80vh",
          // overflow: "auto",
          display: "flex",
          gap: "10px",
          flexDirection: "column",
        }}
        className="jsb-spacing"
      >
        {props.jobs?.map((job, index) => (
          <div
            key={index}
            onClick={() => {
              if (job._id) {
                setHighlightJob(job._id);

                setPopupOpen(true);
              }
            }}
          >
            <JobPost
              currJob={job as JobInt}
              setSelectedJob={setSelectedJob}
              selected={highlightJob === job._id}
            />
          </div>
        ))}
      </div>
      {/* hide when screen size is less than 700 */}

      <div
        className={`w100  job-sidebar-content hide-for-phone ${
          isPinned ? "pinned" : ""
        }`}
      >
        <div className="js-content-top">
          <div style={{ fontSize: "25px" }}>{selectedJob?.title}</div>
          <div style={{ fontSize: "20px" }}>{selectedJob?.company}</div>
          <div style={{ fontSize: "18px", color: "rgba(0,0,0,0.5)" }}>
            {selectedJob?.location.city && selectedJob?.location.country
              ? selectedJob?.location.city +
                ", " +
                selectedJob?.location.country
              : ""}
          </div>
          {/* logic to take user to apply or  */}

          {selectedJob &&
            (selectedJob.recieveViaEmail ? (
              <Link
                to={`/job-des/job-apply/?id=${selectedJob._id}`}
                className="apply-btn-sidebar button-hover"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "white" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                  }}
                >
                  Aplicar
                  <BoltIcon style={{ fontSize: "20px", marginLeft: "5px" }} />
                </div>
              </Link>
            ) : (
              <a
                href={selectedJob.recieveEmail}
                className="apply-btn-sidebar button-hover"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "white" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                  }}
                >
                  Aplicar
                  <OpenInNewIcon
                    style={{ fontSize: "18px", marginLeft: "10px" }}
                  />
                </div>
              </a>
            ))}
        </div>
        {/* ----------- */}
        <div className="js-content-btm">
          {selectedJob?.recieveViaEmail && (
            <div className="js-content-btm-sub">
              <div style={{ fontSize: "20px" }}>Detalles</div>
              {selectedJob.salaryRange &&
                selectedJob.salaryRange[0] &&
                selectedJob.salaryRange[1] && (
                  <div>
                    {" "}
                    {"$" +
                      selectedJob.salaryRange[0] +
                      " - " +
                      "$" +
                      selectedJob.salaryRange[1]}{" "}
                  </div>
                )}

              <div className="flag-container">
                {/* {checkTimeDif() < 10 && <InfoFlag name={"Nuevo"} />} */}

                {!selectedJob.inPerson ? (
                  <InfoFlag name={"Remoto"} color="flag-purple" />
                ) : (
                  <InfoFlag name={"Presencial"} color="flag-purple" />
                )}
                {!selectedJob.fullTime ? (
                  <InfoFlag name={"Medio Tiempo "} color="flag-orange" />
                ) : (
                  <InfoFlag name={"Tiempo Completo"} color="flag-orange" />
                )}
              </div>

              <div className="sidebar-divider"></div>
            </div>
          )}

          <div className="js-content-btm-desc">
            <div style={{ fontSize: "20px" }}>
              {selectedJob?.recieveViaEmail
                ? "Descripción"
                : "Serás redirigido a la página especificada por la empresa"}
            </div>

            {selectedJob?.description && (
              // <div
              //   dangerouslySetInnerHTML={{ __html: selectedJob?.description }}
              //   className="sidebar-content-description "
              //   style={{ maxHeight: "none" }}
              // ></div>
              <TextEditorVisualizer htmlContent={selectedJob.description} />
            )}
          </div>
        </div>
      </div>
      <div className="mobile-only">
        {PopupOpen && selectedJob && (
          <JobDescPopUp selectedJob={selectedJob} setPopOpen={setPopupOpen} />
        )}
      </div>
    </div>
  );
}

export default JobSideDesc;
