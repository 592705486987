import "../../assets/styles/text-editor/textEditor.css";

import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import { EditorContent, useEditor, useEditorState } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import Underline from "@tiptap/extension-underline";
import TitleIcon from "@mui/icons-material/Title";

interface MenuBarProps {
  editor: any;
  editorState: {
    isBold: boolean;
    isItalic: boolean;
    isStrike: boolean;
    isHighlight: boolean;
    headingLevel: number | false;
    textAlign: string | false;
    isUnderline: boolean;
    isBulletList: boolean;
    isOrderedList: boolean;
  };
}

const MenuBar: React.FC<MenuBarProps> = ({ editor, editorState }) => {
  return (
    <div className="control-group">
      <div className="button-group">
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBold().run();
          }}
          className={editorState.isBold ? "is-active" : ""}
        >
          <FormatBoldIcon fontSize="small" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleItalic().run();
          }}
          className={editorState.isItalic ? "is-active" : ""}
        >
          <FormatItalicIcon fontSize="small" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleUnderline().run();
          }}
          className={editorState.isUnderline ? "is-active" : ""}
        >
          <FormatUnderlinedIcon fontSize="small" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBulletList().run();
          }}
          className={editorState.isBulletList ? "is-active" : ""}
        >
          <FormatListBulletedIcon fontSize="small" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleOrderedList().run();
          }}
          className={editorState.isOrderedList ? "is-active" : ""}
        >
          <FormatListNumberedIcon fontSize="small" />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleHeading({ level: 2 }).run();
          }}
          className={editorState.headingLevel === 1 ? "is-active" : ""}
        >
          <TitleIcon fontSize="small" />
        </button>
      </div>
    </div>
  );
};

// Add this interface to define the shape of your editor state
interface EditorState {
  isBold: boolean;
  isItalic: boolean;
  isStrike: boolean;
  isHighlight: boolean;
  headingLevel: number | false;
  textAlign: string | false;
  isUnderline: boolean;
  isBulletList: boolean;
  isOrderedList: boolean;
}

interface TextEditor2Props {
  editorName: string;
  htmlValue: string;
  setHTMLValue: (value: string) => void;
}

function TextEditor2({
  editorName,
  htmlValue,
  setHTMLValue,
}: TextEditor2Props) {
  const editor = useEditor({
    immediatelyRender: true,
    shouldRerenderOnTransaction: false,
    extensions: [
      StarterKit,
      Highlight,
      Underline,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    content: htmlValue,
    editorProps: {
      attributes: {
        class:
          "prose prose-lg focus:outline-none text-editor-content custom-font",
        style: "font-family: Arial, sans-serif;",
      },
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setHTMLValue(html);
    },
  });

  // Add this effect to update editor content when htmlValue changes
  React.useEffect(() => {
    if (editor && htmlValue !== editor.getHTML()) {
      editor.commands.setContent(htmlValue);
    }
  }, [editor, htmlValue]);

  // Update the useEditorState call to use proper typing
  const editorState = useEditorState<EditorState>({
    editor,
    selector: (ctx) => ({
      isBold: ctx.editor?.isActive("bold") ?? false,
      isItalic: ctx.editor?.isActive("italic") ?? false,
      isStrike: ctx.editor?.isActive("strike") ?? false,
      isHighlight: ctx.editor?.isActive("highlight") ?? false,
      headingLevel: ctx.editor?.isActive("heading", { level: 1 })
        ? 1
        : ctx.editor?.isActive("heading", { level: 2 })
        ? 2
        : ctx.editor?.isActive("heading", { level: 3 })
        ? 3
        : false,
      textAlign: ctx.editor?.isActive({ textAlign: "left" })
        ? "left"
        : ctx.editor?.isActive({ textAlign: "center" })
        ? "center"
        : ctx.editor?.isActive({ textAlign: "right" })
        ? "right"
        : ctx.editor?.isActive({ textAlign: "justify" })
        ? "justify"
        : false,
      isUnderline: ctx.editor?.isActive("underline") ?? false,
      isBulletList: ctx.editor?.isActive("bulletList") ?? false,
      isOrderedList: ctx.editor?.isActive("orderedList") ?? false,
    }),
  });

  if (!editor) {
    return null;
  }

  return (
    <>
      <div
        className="postjob-container-title"
        style={{ marginBottom: "10px", marginTop: "25px", fontSize: "18px" }}
      >
        {editorName}
      </div>
      <div className="text-editor-container">
        <MenuBar editor={editor} editorState={editorState} />

        <EditorContent editor={editor} />
      </div>
    </>
  );
}

export default TextEditor2;
