import React, { useCallback, useEffect, useState, useMemo } from "react";
import { JobInt } from "../../typescript/interfaces/JobInterface";
import JobDropDown from "./tools/JobDropDown";
import {
  ApplicantInt,
  OptionsInt,
} from "../../typescript/interfaces/AppInterface";
import {
  filterApplicantsByState,
  getApplicantsById,
  handleApplicantState,
  updateApplicantById,
} from "../../utils/applicantFunctions";
import ApplicantSidebar from "../applicants-sidebar/ApplicantSidebar";
import LoadingWidget from "../widgets/LoadingWidget";
import { filterApplicantsByLocation } from "../../utils/locationUtils"; // Add this import
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterListIcon from "@mui/icons-material/FilterList"; // Add this import
import ApplicantFilterPopUp from "./ApplicantFilterPopUp";
import FilterDropdown from "./tools/FilterDropdown";

interface ApplicantsProps {
  jobs: JobInt[];
}

// update date to today on payment confirmation for 45 day calculation

// TODO:  add state management for the applicants and filtering
// states: Todos ,  Nuevos , Guardados,  Rechazados

// Frontend
// add dropdown for the new states
// add filtering for the new states i client
//  state: "Nuevos" | "Guardados" | "Rechazados";

function Applicants(props: ApplicantsProps) {
  const getInitialJob = useCallback(() => {
    const storedJobId = localStorage.getItem("selectedJobId");
    if (storedJobId && props.jobs.length > 0) {
      const storedJob = props.jobs.find((job) => job._id === storedJobId);
      if (storedJob) {
        return storedJob;
      }
    }
    return props.jobs[0];
  }, [props.jobs]);
  const [selectedJob, setSelectedJob] = useState(getInitialJob);
  const [jobOptions, setJoboptions] = useState<OptionsInt[]>([]);
  const [applicants, setApplicants] = useState<ApplicantInt[]>([]);
  const [loading, setLoading] = useState(true);
  const [locationFilter, setLocationFilter] = useState<
    "Todos los aplicantes" | "Dentro de la zona" | "Afuera de la zona"
  >("Todos los aplicantes");
  const [applicantsStateFilter, setApplicantsStateFilter] = useState<
    "Todos" | "Nuevos" | "Guardados" | "Rechazados"
  >("Todos");
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] =
    useState<ApplicantInt | null>(null);

  const handleOptionClick = (option: OptionsInt) => {
    setSelectedJob(option.value);
    // add to cache
  };

  const handleLocationFilterChange = (
    filter: "Todos los aplicantes" | "Dentro de la zona" | "Afuera de la zona"
  ) => {
    setLocationFilter(filter);
  };

  const toggleLocationDropdown = () => {
    setIsLocationDropdownOpen(!isLocationDropdownOpen);
  };

  const filteredApplicants = useMemo(() => {
    let returnArray: ApplicantInt[] = applicants;

    // Apply location filter if applicable
    if (locationFilter !== "Todos los aplicantes" && selectedJob.mapResults) {
      returnArray = filterApplicantsByLocation(
        returnArray,
        selectedJob.mapResults,
        locationFilter === "Dentro de la zona"
      );
    }

    // Apply state filter if not "Todos"
    if (applicantsStateFilter !== "Todos") {
      returnArray = filterApplicantsByState(returnArray, applicantsStateFilter);
    }

    return returnArray;
  }, [
    locationFilter,
    selectedJob.mapResults,
    applicantsStateFilter,
    applicants,
  ]);

  const setApplicantLike = async (
    event: React.MouseEvent,
    selectedApplicant: ApplicantInt
  ) => {
    event.stopPropagation();

    // Create an updated applicant with the liked status toggled
    const updatedApplicant = {
      ...selectedApplicant,
      liked: !selectedApplicant.liked,
    };

    // Optimistically update the local state
    setApplicants((prevApplicants) =>
      prevApplicants.map((applicant) =>
        applicant._id === selectedApplicant._id ? updatedApplicant : applicant
      )
    );

    // Attempt to update the applicant in the backend
    try {
      const result = await updateApplicantById(selectedApplicant);

      // If the update fails, revert the change

      if (!result) {
        throw new Error("Failed to update applicant");
      }
    } catch (error) {
      console.error("Error updating applicant:", error);

      // Revert the optimistic update
      setApplicants((prevApplicants) =>
        prevApplicants.map((applicant) =>
          applicant._id === selectedApplicant._id
            ? selectedApplicant
            : applicant
        )
      );

      // Optionally, show an error message to the user
      // showErrorMessage('Failed to update applicant. Please try again.');
    }
  };

  const setApplicantsState = async (
    event: React.MouseEvent<HTMLDivElement>,
    selectedApplicant: ApplicantInt,
    state: "Nuevos" | "Guardados" | "Rechazados"
  ) => {
    // update the state to the new state
    event.stopPropagation();

    const updatedApplicant = {
      ...selectedApplicant,
      state: state,
    };
    setApplicants((prevApplicants) =>
      prevApplicants.map((applicant) =>
        applicant._id === selectedApplicant._id ? updatedApplicant : applicant
      )
    );

    // Update the selected applicant if it's the one being modified
    if (selectedApplicant._id === updatedApplicant._id) {
      setSelectedApplicant(updatedApplicant);
    }

    try {
      //  try to update the applicant in the backend
      // on Sucess

      const result = await handleApplicantState(updatedApplicant, state);
      if (!result) {
        throw new Error("Failed to update applicant");
      }
    } catch (error) {
      console.error("Error updating applicant:", error);

      setApplicants((prevApplicants) =>
        prevApplicants.map((applicant) =>
          applicant._id === selectedApplicant._id
            ? selectedApplicant
            : applicant
        )
      );
    }
  };

  const handleSelectedApplicant = useCallback((applicant: ApplicantInt) => {
    setSelectedApplicant(applicant);
  }, []);

  useEffect(() => {
    const getJobOptions = () => {
      if (props.jobs) {
        const options = props.jobs.map((job) => {
          return { value: job, label: job.title };
        });
        setJoboptions(options);
      }
    };
    const getApplicants = async () => {
      setLoading(true);
      if (selectedJob._id) {
        const appList = await getApplicantsById(selectedJob._id);
        // aqui?
        if (appList) {
          setApplicants(appList);
          setSelectedApplicant(appList[0]);
        }
      }
      setLoading(false);
    };

    getJobOptions();
    getApplicants();
  }, [props.jobs, selectedJob]);

  useEffect(() => {
    // Store the current page number in localStorage
    if (selectedJob._id) localStorage.setItem("selectedJobId", selectedJob._id);
  }, [selectedJob]);

  useEffect(() => {
    if (filteredApplicants.length > 0) {
      // when it says todos does not reset
      if (applicantsStateFilter !== "Todos") {
        setSelectedApplicant(filteredApplicants[0]);
      }
    } else {
      setSelectedApplicant(null);
    }
  }, [applicantsStateFilter, filteredApplicants]);

  const locationFilterMappings = {
    "Todos los aplicantes": "Todos",
    "Dentro de la zona": "Dentro",
    "Afuera de la zona": "Afuera",
  };

  return (
    <div
      className="w100"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "0px 10px",
      }}
    >
      <div className="w100" style={{ maxWidth: "1000px", height: "100%" }}>
        <div className="flx" style={{ justifyContent: "space-between" }}>
          {!loading && (
            <React.Fragment>
              <JobDropDown
                options={jobOptions}
                value={{ value: selectedJob, label: selectedJob.title }}
                onSelect={handleOptionClick}
              />

              {/* Filters  */}
              <div className="flx" style={{ alignItems: "flex-end" }}>
                <div className="desktop-only">
                  <FilterDropdown
                    state={applicantsStateFilter}
                    setState={
                      setApplicantsStateFilter as React.Dispatch<
                        React.SetStateAction<string>
                      >
                    }
                    options={["Todos", "Nuevos", "Guardados", "Rechazados"]}
                  />
                </div>

                <div className="location-applicant-filter-container ">
                  {selectedJob.mapResults && (
                    <React.Fragment>
                      <div
                        className={
                          "location-applicant-filter-dropdown desktop-only"
                        }
                        onClick={toggleLocationDropdown}
                      >
                        <div
                          className={`location-applicant-filter-box ${
                            isLocationDropdownOpen
                              ? "location-dropdown-open"
                              : ""
                          }`}
                        >
                          <div>
                            <span style={{ color: "rgba(0,0,0,0.6)" }}>
                              Ubicación:
                            </span>{" "}
                            {locationFilterMappings[locationFilter]}
                          </div>
                          <span className="applicant-dropdown-arrow">
                            {isLocationDropdownOpen ? (
                              <ArrowDropDownIcon
                                style={{ transform: "rotate(180deg)" }}
                              />
                            ) : (
                              <ArrowDropDownIcon />
                            )}
                          </span>
                        </div>
                        {isLocationDropdownOpen && (
                          <div className="location-applicant-filter-dropdown-options">
                            <div
                              className={`dropdown-option ${
                                locationFilter === "Todos los aplicantes"
                                  ? "location-dropdown-option-selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleLocationFilterChange(
                                  "Todos los aplicantes"
                                )
                              }
                            >
                              Todos los aplicantes
                            </div>
                            <div
                              className={`dropdown-option ${
                                locationFilter === "Dentro de la zona"
                                  ? "location-dropdown-option-selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleLocationFilterChange("Dentro de la zona")
                              }
                            >
                              Dentro de la zona
                            </div>
                            <div
                              className={`dropdown-option ${
                                locationFilter === "Afuera de la zona"
                                  ? "location-dropdown-option-selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleLocationFilterChange("Afuera de la zona")
                              }
                            >
                              Afuera de la zona
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                  <div className="mobile-only">
                    <FilterListIcon
                      onClick={toggleLocationDropdown}
                      style={{ fontSize: "26px" }}
                    />
                  </div>
                  {isLocationDropdownOpen && (
                    <ApplicantFilterPopUp
                      name={"Filtros"}
                      setPopUpOpen={setIsLocationDropdownOpen}
                      locationFilterChange={locationFilter}
                      handleLocationFilterChange={handleLocationFilterChange}
                      handleStateFilterChange={setApplicantsStateFilter}
                      stateFilterChange={applicantsStateFilter}
                      showLocationFilter={selectedJob.mapResults ? true : false}
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        {/* display jobs */}
        {!loading ? (
          filteredApplicants.length > 0 ? (
            <ApplicantSidebar
              aplicants={filteredApplicants}
              setApplicantLike={setApplicantLike}
              setApplicantsState={setApplicantsState}
              selectedApplicant={selectedApplicant || filteredApplicants[0]}
              setSelectedApplicant={handleSelectedApplicant}
            />
          ) : (
            <div>No hay candidatos que cumplan con los criterios de filtro</div>
          )
        ) : (
          // center
          <div className="flx flx-center mt-25">
            <LoadingWidget loading={true} />
          </div>
        )}
      </div>
      {/* add the list of applicants  */}
    </div>
  );
}

export default Applicants;
